import getWikiData from "../http/wikiAxios/getWikiData";
import router from "../../router/index";

const handleData = (list = [], path = "", ident = "") => {
	if (!list.length) return [];
	let result = [];
	list.forEach((item, index) => {
		const name = path ? `${path}/${item.name}` : item.name;
		const link = ident !== "" ? `${ident}-${index + 1}` : `${index + 1}`;
		const children = handleData(item?.children || [], name, link);
		const params = {
			...item,
			children,
			path: name,
			link,
		};
		result.push(params);
	});
	return result;
};

export default {
	namespaced: true,
	state: {
		treeList: [],
		currentObj: {},
	},
	getters: {},
	actions: {
		async queryTree({ commit, state, rootGetters, rootState, dispatch }) {
			const { data } = await getWikiData.getTree();
			const tree = handleData(data);
			commit("merge", {
				treeList: tree,
			});
		},
		handleNodeClick({ commit }, data) {
			const [id] = data.link.split("-");
			if (id === "3") {
				if (data.level == 6) {
					router.push({
						name: "rdApi",
						query: { id: data.id },
					});
				} else {
					// this.$refs.tree.setCurrentKey(data.id);

					router.push({
						name: "rdArticle",
						query: {
							id: data.link,
						},
					});
				}
				return;
			}
			if (data.name == "测试数据") {
				router.push({
					name: "rdTest",
				});
				return;
			}
			if (data.name == "运维支持") {
				router.push({
					name: "rdOperation",
				});
				return;
			}
			commit("merge", {
				currentObj: data,
			});
			router.push({
				name: "rdNotFind",
				query: { id: data.link },
			});
		},
	},
	mutations: {
		merge(state, payload) {
			Object.assign(state, payload);
		},
	},
};

import Vue from "vue";
import VueRouter from "vue-router";
import home from "./home";
import knowledge from "./knowledge";
import rdSupport from "./rdSupport";
import meriDesign from "./meriDesign";
import thingsInternet from "./thingsInternet";
import community from "./community";
import publishing from "./publishing";
import testData from "./testData";
import yushupedia from "./yushupedia";
import { mapMutations, mapState } from "vuex";
import store from "../store/index";
import getWikiData from "@/store/http/wikiAxios/getWikiData.js";
Vue.use(VueRouter);

// 获取原型对象push函数
const originalPush = VueRouter.prototype.push;

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace;

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err);
};

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
	return originalReplace.call(this, location).catch((err) => err);
};

const routes = [
	{
		path: "/",
		component: () => import("../views/layouts/Main.vue"),
		redirect: "/home",
		children: [
			...home,
			...rdSupport,
			...meriDesign,
			...thingsInternet,
			...community,
			...publishing,
			...testData,
			...yushupedia,
		],
	},
	{
		path: "/:catchAll(.*)",
		name: "404",
		component: () => import("../views/error/404.vue"),
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	scrollBehavior: () => ({ y: 0 }),
	routes,
});

router.beforeEach(async (to, from, next) => {
	const version = store.state.versionSel.versionCode;
	if (!version) {
		try {
			const res = await getWikiData.getWikiQueryVersion();
			store.commit("changeVersionSel", res.data[0]);
			next();
		} catch (error) {
			console.log(error);
			next();
		}
	} else {
		next();
	}
});

export default router;

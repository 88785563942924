import sdk from "@/store/http/wikiPost";
export default {
	//获取版本列表
	async getWikiQueryVersion() {
		var result = await sdk.wikiHttpGet("knowledge/queryVersion");
		return result;
	},
	//获取对象列表
	async getWikiObjectType(params) {
		var result = await sdk.wikiHttpGet("knowledge/queryObjectType", params);
		return result;
	},
	//获取对象详情
	async getWikiObjectDetail(params) {
		var result = await sdk.wikiHttpGet("knowledge/getObjectTypeDetail", params);
		return result;
	},
	//获取信息点
	async getWikiQueryDefineInfo(params) {
		var result = await sdk.wikiHttpGet("knowledge/queryDefineInfo", params);
		return result;
	},
	//获取可建立关系
	async getWikiQueryObjectRelationCount(params) {
		var result = await sdk.wikiHttpGet(
			"knowledge/queryObjectRelationCount",
			params
		);
		return result;
	},
	//获取可建立关系列表
	async getWikiQueryObjectRelation(params) {
		var result = await sdk.wikiHttpGet("knowledge/queryObjectRelation", params);
		return result;
	},
	//获取知识库左树
	async getWikiQueryKnowledgeMenu() {
		var result = await sdk.wikiHttpGet("knowledge/queryKnowledgeMenu");
		return result;
	},
	//获取知识库列表内容
	async getWikiQueryKnowledgeContent(params) {
		var result = await sdk.wikiHttpGet(
			"knowledge/queryKnowledgeContent",
			params
		);
		return result;
	},
	//获取知识的总数
	async queryKnowledgeCount() {
		var result = await sdk.wikiHttpGet("knowledge/queryKnowledgeCount");
		return result;
	},
	//获取三维模型系统设备配置
	async querySystemEquipmentConfig(params) {
		var result = await sdk.wikiHttpGet(
			"knowledge/querySystemEquipmentConfig",
			params
		);
		return result;
	},
	//获取3d图标库
	async getIcon(params) {
		var result = await sdk.admGet(
			"https://www.yushu.biz/apishow/getPublicComponent",
			params
		);
		return result;
	},
	//获取3d图标库
	async getIcons(params) {
		var result = await sdk.admGet(
			"https://www.yushu.biz/revitapi/getComponents",
			params
		);
		return result;
	},
	//获取2d图标
	async getApmIcon(params) {
		var result = await sdk.admGet(
			"https://qa-paasgw.yushu.cloud/apm-aim/graph/legend/query/guest/" + params
		);
		return result;
	},

	//api左树列表
	async getApiLeftTree() {
		var result = await sdk.wikiHttpGet("knowledge/api/listProject");
		return result;
	},

	//api接口列表
	async getApiList(params) {
		var result = await sdk.wikiHttpGet("/knowledge/api/listInterface", params);
		return result;
	},

	//api接口详情
	async getApiInterface(params) {
		var result = await sdk.wikiHttpGet("/knowledge/api/interface/get", params);
		return result;
	},

	//api下拉
	async getApiSelect(params) {
		var result = await sdk.wikiHttpGet(
			"/knowledge/api/listProjectById",
			params
		);
		return result;
	},
	//api接口地址
	async getApiaddress(params) {
		var result = await sdk.wikiHttpGet("/knowledge/api/project/get", params);
		return result;
	},

	//树
	async getTree() {
		var result = await sdk.wikiHttpGet("/knowledge/api/getTree");
		return result;
	},
	async getSpaceKnowledge() {
		var result = await sdk.wikiHttpGet("/knowledge/getSpaceKnowledge");
		return result;
	},

	//需求列表
	async getDemandList(params) {
		const result = await sdk.wikiHttpPost("/knowledge/demand/list", params);
		return result;
	},

	//创建需求
	async addDemand(params) {
		const result = await sdk.wikiHttpPost("/knowledge/demand/add", params);
		return result;
	},

	//需求详情
	async getDemandDetail(params) {
		var result = await sdk.wikiHttpGet("/knowledge/demand/detail", params);
		return result;
	},

	//需求下的任务
	async getDemandTask(params) {
		var result = await sdk.wikiHttpGet("/knowledge/demand/demandTask", params);
		return result;
	},

	//任务详情
	async getTaskDetail(params) {
		var result = await sdk.wikiHttpGet("/knowledge/demand/taskDetail", params);
		return result;
	},

	//提出人列表
	async getCreatorList(params) {
		var result = await sdk.wikiHttpGet("/knowledge/demand/creatorList", params);
		return result;
	},

	//负责人列表
	async getProcessorList(params) {
		var result = await sdk.wikiHttpGet(
			"/knowledge/demand/processorList",
			params
		);
		return result;
	},

	//上传附件
	async uploadAttachments(params, fn) {
		const result = await sdk.wikiFormDataPost(
			"/knowledge/demand/uploadAttachments",
			params,
			fn
		);
		return result;
	},
	//下载附件
	async downAttachments(params) {
		const result = await sdk.wikiDownPost(
			"/knowledge/demand/downAttachments",
			params
		);
		return result;
	},

	//上传图片
	async uploadImage(params) {
		const result = await sdk.wikiFormDataPost(
			"/knowledge/file/uploadImage",
			params
		);
		return result;
	},
	//获取评论
	async getCommentList(params) {
		var result = await sdk.wikiHttpGet("/knowledge/demand/commentList", params);
		return result;
	},
	//获取状态列表
	async getStatusList(params) {
		var result = await sdk.wikiHttpGet("/knowledge/demand/statusList", params);
		return result;
	},
};

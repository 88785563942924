const rdSupport = [
	{
		path: "/rd-support",
		name: "rdSupport",
		component: () => import("../views/rdSupport/index.vue"),
		redirect: "/rd-home",
		children: [
			{
				path: "/rd-home",
				name: "rdHome",
				component: () => import("../views/rdSupport/home.vue"),
			},
			{
				path: "/rd-article",
				name: "rdArticle",
				component: () => import("../views/rdSupport/article/index.vue"),
			},
			{
				path: "/rd-introductory",
				name: "rdIntroductory",
				component: () => import("../views/rdSupport/rdIntroductory.vue"),
			},
			{
				path: "/rd-technical",
				name: "rdTechnical",
				component: () => import("../views/rdSupport/rdTechnical.vue"),
			},
			{
				path: "/rd-standard",
				name: "rdStandard",
				component: () => import("../views/rdSupport/rdStandard.vue"),
			},
			{
				path: "/rd-api",
				name: "rdApi",
				component: () => import("../views/rdSupport/rdApi.vue"),
			},
			{
				path: "/rd-test",
				name: "rdTest",
				component: () => import("../views/rdSupport/rdTest.vue"),
			},
			{
				path: "/rd-operation",
				name: "rdOperation",
				component: () => import("../views/rdSupport/rdOperation.vue"),
			},
			{
				path: "/testTable",
				name: "testTable",
				component: () => import("../views/testData/testTable.vue"),
			},
			{
				path: "/rd-notFind",
				name: "rdNotFind",
				component: () => import("../views/rdSupport/404.vue"),
			},
		],
	},
];

export default rdSupport;

const state = {
	versionSel: {},
};
const getters = {};
const mutations = {
	changeVersionSel(state, params) {
		state.versionSel = params;
	},
};
const actions = {};
const store = {
	state,
	getters,
	mutations,
	actions,
};

export default store;

import axios from "axios";
import store from "../index";
const CancelToken = axios.CancelToken;
let cancel;

// 创建axios实例
const axiosservice = axios.create({
	timeout: 50000, // 请求超时时间
	retry: 4, //重新请求次数
	retryDelay: 1000, //重新请求的间隔
	headers: {
		"Content-Type": "application/json;charset=UTF-8",
	},
	cancelToken: new CancelToken(function executor(c) {
		// executor 函数接收一个 cancel 函数作为参数
		cancel = c;
	}),
});

axiosservice.interceptors.request.use(
	(config) => {
		config.withCredentials = false; // 允许携带token ,这个是解决跨域产生的相关问题
		config.headers.Token =
			"eyJhbGciOiJIUzI1NiJ9.eyJkZXZpY2VUeXBlIjoxLCJhdWQiOiJ5dXNodS1zYWFzLWNsaWVudCIsInN1YiI6Inl1c2h1LXVzZXIiLCJhY2NvdW50SWQiOiIxNTAxODMxOTYyNTE0MjQzNTg1IiwiYWNjb3VudE5hbWUiOiLpg63mr4Xpo54iLCJhY2NvdW50VHlwZSI6IjEiLCJpc3MiOiJ5dXNodSIsImp0aSI6IjEyOTc1NzJiLTdlZWEtNDdmOC1hZDQwLTk5MzJhMGE5Y2I4MiJ9.33yFkRTIV9azumKxJ9su0NT2mj8oMUtfv1nSyvmOmi0";
		if (store.state.versionSel.versionCode)
			config.headers.versionCode = encodeURIComponent(
				store.state.versionSel.versionCode
			);

		return config;
	},
	(error) => {
		console.error("request：", error);
		return Promise.reject(error);
	}
);

axiosservice.interceptors.response.use(
	function (res) {
		return res;
	},
	function (err) {
		console.error("response: ", err);
		return Promise.reject(err);
	}
);

export default {
	async PostJson(url, data) {
		var res = await axiosservice({ url, data, method: "post" });
		try {
			if (typeof res.data == "object") {
				return res.data;
			}
		} catch (error) {
			throw new Error("接口返回失败：" + res.data);
		}
	},
	async GetJson(url) {
		var res = await axiosservice({ url, method: "get" });
		try {
			if (typeof res.data == "object") {
				return res.data;
			}
		} catch (error) {
			throw new Error("接口返回失败：" + res.data);
		}
	},
	axios: axiosservice,
};

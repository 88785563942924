const community = [
	{
		path: "/community",
		name: "community",
		component: () => import("../views/community/index.vue"),
	},
	{
		path: "/REQM",
		name: "REQM",
		component: () => import("../views/community/REQM.vue"),
	},
];

export default community;

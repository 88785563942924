import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import "./utils/rem"
import 'reset-css'
import 'element-ui/lib/theme-chalk/index.css';
import store from "@/store/index.js"
import Element from 'element-ui';

import router from './router';
import * as echarts from 'echarts';//引入echarts
import MeriD from 'meri-design';
import 'meri-design/dist/index.css'
Vue.use(MeriD);
Vue.prototype.$echarts = echarts //引入组件
Vue.use(Element);
Vue.prototype.$axios = axios;

Vue.config.productionTip = false



import './assets/font/font.css'

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
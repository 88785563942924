import global from "./global";
import Vue from "vue";
import Vuex from "vuex";
import rdSupport from "./module/rdSupport";
Vue.use(Vuex);

const store = new Vuex.Store({
	...global,
	modules: {
		rdSupport,
	},
});
export default store;
